module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"vivenda-labs","short_name":"vivenda","start_url":"/","background_color":"#fdf8ed","theme_color":"#fdf8ed","display":"minimal-ui","icon":"src/images/vivenda-logo-tmp.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ddb24b7a90d02ca729de64ea015baaa6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
